<template>
  <Portlet
    title="Problems"
    icon="exclamation-triangle"
    class="triggerList_Operation"
  >
    <template slot="buttons">
      <font-awesome-icon
        v-tooltip="{ content: dateTime_dateTime(lastRefresh), position: 'top'}"
        :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loading}]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="getTriggers()"
      />
      <div
        v-if="!authenticationHasRole('zerolevel')"
        class="btn btn-group-sm btn-group m-0 p-0 d-none d-sm-block"
      >
        <router-link
          v-if="unacknowledgedEventsAvailable"
          :to="`/problem/acknowledgeall/${ installationId }/installation`"
          class="btn btn-sm btn-primary"
        >
          {{ $t('acknowledgeAll') }}
        </router-link>
        <button
          v-else
          class="btn btn-sm btn-primary"
          disabled
        >
          {{ $t('acknowledgeAll') }}
        </button>
        <a
          v-if="groupId"
          :href="zabbixLinkToLatestData"
          target="_blank"
          class="btn btn-sm btn-primary"
        >{{ $t('latestData') }}</a>
        <a
          v-if="groupId"
          :href="zabbixLinkToTriggers"
          target="_blank"
          class="btn btn-sm btn-primary"
        >{{ $t('problems') }}</a>
      </div>
    </template>
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <!-- bp -->
      <div class="container-fluid problems">
        <div
          v-for="(trigger, index) in triggers"
          :key="index"
          class="row"
          :style="`border-left:4px solid ${ triggerPriorityMixin_priorityClass(trigger.lastEventSeverity) }`" 
        >
          <div class="border-col col-12 col-xl-7">
            <span class="font-weight-bold">{{ trigger.hosts[0] ? trigger.hosts[0].name : '' }}</span>
            <span>: {{ trigger.description }}</span>
          </div>
          <div class="border-col col-6 col-xl-3">
            <span v-if="authenticationHasRole('zerolevel') && !trigger.acknowledged">
              Unacknowledged
            </span>
            <router-link
              v-else-if="!authenticationHasRole('zerolevel') && !trigger.acknowledged"
              :to="`/problem/acknowledge/${ trigger.triggerId }/${ trigger.installation }/installation`"
              class="btn btn-primary btn-block btn-sm mt-2"
            >
              Acknowledge
            </router-link>
            <div
              v-else
              class="row h-100 justify-content-center acknowledged"
            >
              <span class="symbol">
                ✔
              </span>
              <router-link
                :to="`/problem/acknowledge/${ trigger.triggerId }/${ trigger.installation }/installation`"
                class="symbol ml-2"
              >
                <font-awesome-icon
                  icon="edit"
                />
              </router-link>
            </div>
          </div>
          <div class="border-col col-6 col-xl-2">
            <button
              class="btn btn-primary btn-block btn-sm mt-2"
              @click="openDetailSidebar(trigger.triggerId)"
            >
              Details
            </button>
          </div>
        </div>
      </div>
      <!-- bp -->

      <Sidebar
        :show-sidebar="detailSidebarIsVisible"
        :sidebar-width="600"
        @close="closeDetailSidebar"
      >
        <template v-if="detailSidebarId != null">
          <table class="defaultTable">
            <colgroup>
              <col width="33.333%">
              <col width="66.666%">
            </colgroup>
            <tbody>
              <tr v-if="getDetailSidebarTrigger.description">
                <th class="bold">
                  Name
                </th>
                <td>
                  <span>{{ getDetailSidebarTrigger.description }}</span>
                </td>
              </tr>
              <tr v-if="getDetailSidebarTrigger.comments">
                <th class="bold">
                  Description
                </th>
                <td>
                  <span>{{ getDetailSidebarTrigger.comments }}</span>
                </td>
              </tr>
              <tr v-if="getDetailSidebarTrigger.lastchange">
                <th class="bold">
                  Changed
                </th>
                <td
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <span v-tooltip="dateTime_fromNow(getDetailSidebarTrigger.lastchange)">{{ getDetailSidebarTrigger.lastchange | dateTime_dateTime }}</span>
                </td>
              </tr>
              <tr v-if="getDetailSidebarTrigger.lastEventSeverity">
                <th class="bold">
                  Priority
                </th>
                <td>
                  <span>{{ triggerPriorityMixin_priorityText(getDetailSidebarTrigger.lastEventSeverity) }}</span>
                </td>
              </tr>
              <tr v-if="getDetailSidebarTrigger.hosts">
                <th class="bold">
                  Hosts
                </th>
                <td>
                  <a
                    :href="zabbixHref"
                    target="_blank"
                  >{{ getDetailSidebarTrigger.hosts[0].name }}</a>
                </td>
              </tr>
              <tr v-if="getDetailSidebarTrigger.groups">
                <th class="bold">
                  Groups
                </th>
                <td>
                  <span
                    v-for="(group, index) in getDetailSidebarTrigger.groups"
                    :key="'table1' + index"
                    class="badge badge-pill badge-info mr-1 mt-1"
                  >{{ group.name }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="m-0 mb-3">
          <Portlet
            v-if="acknowledges"
            title="Acknowledges"
            icon="bell"
            class="mb-2"
          >
            <AcknowledgeList
              :acknowledges="acknowledges"
            />
          </Portlet>
          <Portlet
            :title="$t('problemDetailView.events')"
            icon="bell"
          >
            <template slot="buttons">
              <button
                class="btn btn-sm btn-primary"
                @click="setLimitAllEvents(!limitAllEvents)"
              >
                {{ limitAllEvents ? $t('problemDetailView.loadAllEvents') : $t('problemDetailView.loadLessEvents') }}
              </button>
            </template>
            <EventList
              :trigger-id="detailSidebarId"
              :host-id="getDetailSidebarTrigger.hosts[0].hostId"
              :limit-events="limitAllEvents"
              @setBack="setLimitAllEvents(true)"
              @setAck="setAck"
            />
          </Portlet>
        </template>
      </Sidebar>
    </template>
  </Portlet>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';

import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { urlParseMixin } from '@/mixins/urlParseMixin.js';
import { triggerPriorityMixin } from '@/mixins/triggerPriorityMixin.js';
import { mapGetters } from 'vuex';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: 'TriggerListOperation',
  components: {
    Sidebar,
    EventList: () => import('@/components/Problem/EventList.vue'),
    AcknowledgeList: () => import('@/components/Problem/AcknowledgeList.vue')
  },
  mixins: [
    urlParseMixin,
    dateTimeMixin,
    triggerPriorityMixin,
    authenticationMixin,
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      triggers: null,
      loading: true,
      acknowledgeStatus: 0,
      groupId: null,
      detailSidebarIsVisible: false,
      detailSidebarId: null,
      lastRefresh: new Date(),
      cancelSource: null,
      acknowledges: null,
      limitAllEvents: true
    }
  },
  computed: {
    getDetailSidebarTrigger () {
      return this.triggers.find(trigger => trigger.triggerId == this.detailSidebarId);
    },
    ...mapGetters([
      'store_getZabbixUrl'
    ]),
    styleByValue: function () {
      if (this.getDetailSidebarTrigger.value === '0') {
        return "color: black;";
      }
      return "color: orange;"
    },
    textByValue: function () {
      if (this.getDetailSidebarTrigger.value === '0') {
        return "unknown";
      }
      return "problem"
    },
    classByStatus: function () {
      if (this.getDetailSidebarTrigger.value === '1') {
        return "green";
      }
      return "orange"
    },
    textByStatus: function () {
      if (this.getDetailSidebarTrigger.value === '1') {
        return "enabled";
      }
      return "disabled"
    },
    zabbixHref: function () {
      return this.urlParseMixin_combine(this.store_getZabbixUrl,`hosts.php?form=update&hostid=${ this.getDetailSidebarTrigger.hosts[0].hostId }`);
    },
    unacknowledgedEventsAvailable: function () {
      if (!this.triggers) {
        return;
      }
      let unacknowledgedEvents = null;
      unacknowledgedEvents = this.triggers.filter(trigger => {
        return trigger.acknowledged == false
      });
      return unacknowledgedEvents.length > 0;
    },
    priorityTypes () {
      if (!this.triggers) return [ "" ];
      //Distinct (include every entry only once)
      let priorityTypes = Array.from(new Set(this.triggers.map(o=>o.lastEventSeverity)));
      //Remove null values
      priorityTypes = priorityTypes.filter((e) => {return e});
      priorityTypes = priorityTypes.map((e) => {return {text: this.triggerPriorityMixin_priorityText(e), num: e}});
      return priorityTypes.sort();
    },
//#region Computed Links
    zabbixLinkToLatestData () {
      return this.urlParseMixin_combine(this.store_getZabbixUrl, `zabbix.php?action=latest.view&filter_groupids%5B%5D=${ this.groupId }&filter_set=1`);
    },
    zabbixLinkToTriggers () {
      return this.urlParseMixin_combine(this.store_getZabbixUrl, `zabbix.php?action=problem.view&filter_show=1&filter_groupids%5B%5D=${ this.groupId }&filter_set=1&filter_show_suppressed=1`);
    },
//#endregion
  },
  created () {
    this.$eventBus.$on('refreshProblems', this.getTriggers);
    this.$eventBus.$on('openDetailSidebar', this.openDetailSidebar);
  },
  mounted () {
    this.getGroups();
    this.getTriggers();
    this.refreshTriggers = window.setInterval(() => {
      this.getTriggers();
    }, 60000 );
  },
  beforeDestroy () {
    window.clearInterval(this.refreshTriggers);
    this.cancelRequest();
    this.$eventBus.$off('refreshProblems', this.getTriggers);
    this.$eventBus.$off('openDetailSidebar', this.openDetailSidebar);
  },
  methods: {
    setAck (data) {
      this.acknowledges = data;
    },
    closeDetailSidebar () {
      this.detailSidebarIsVisible = false;
      this.detailSidebarId = null;
      this.acknowledges = null;
    },
    openDetailSidebar (value) {
      this.detailSidebarId = value;
      this.detailSidebarIsVisible = true;
    },
    createGroupedTriggers () {
      if (!this.triggers) {
        return;
      }
      
      this.triggers.forEach(trigger => {
        let foundGroup = trigger.groups.find(group => {
          return group.name.toLowerCase().startsWith('hg-skisup');
        });
        
        if (foundGroup) {
          trigger.installation = foundGroup.name.replace('HG-', '');
        }
        
        trigger.installationAndDescription = trigger.hosts[0] ? trigger.hosts[0].name : '' + ": " + trigger.description;
      });
      this.loading = false;
    },
    setLimitAllEvents (val) {
      this.limitAllEvents = val;
    },
//#region API-calls
    cancelRequest () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    },
    getGroups () {
      this.axios.get(`/MonitoringSystem/GetZabbixIdByInstallationId?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.groupId = response.data;
        })
    },
    getTriggers () {
      this.loading = true;
      this.cancelRequest();
      this.cancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelSource.token };
      this.acknowledgeStatus = 2;
      this.axios.get(`/MonitoringSystem/GetTriggersByInstallationId?installationId=${ this.installationId }`, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.triggers = response.data;
          this.triggers.sort(function (a, b) { return a.lastEventSeverity < b.lastEventSeverity ? 1 : -1 });
          this.lastRefresh = new Date();
          this.createGroupedTriggers();
          this.cancelSource = null;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
//#endregion
  }
}
</script>

<style scoped>
.border-col {
  border: 1px solid #ececec;
  box-sizing: border-box;
  display: table-cell;
}
.row:not(:last-child) .border-col {
  border-bottom-width: 0;
}
.border-col:first-child {
  border-bottom-width: 0;
}
.border-col:nth-child(2) {
  border-top-width: 0;
  border-right-width: 0;
}
.border-col:nth-child(3) {
  border-left-width: 0;
  border-top-width: 0;
}
.problems .btn {
  margin-bottom: 4px;
}
.acknowledged {
  background-color:rgba(152, 247, 157, 0.15);
  color:darkgreen;
  font-weight:bold;
}
.acknowledged .symbol {
  margin-top:auto;
  margin-bottom:auto;
}

@media (min-width: 1200px) {
  .border-col:first-child {
    border-bottom-width: 1px;
  }  
  .border-col:not(:last-child) {
    border-right-width: 0;
  }
  .border-col:nth-child(2) {
    border-top-width: 1px;
    border-right-width: 1px;
  }
  .border-col:nth-child(3) {
    border-top-width: 1px;
    border-right-width: 1px;
  }
}
</style>

