export const triggerPriorityMixin = {
    data: function () {
      return {
        triggerPriorityMixin_priorityMapping: [
            {key: 0, name: 'notClassified', text: 'not classified', color: 'grey'},
            { key: 1, name: 'info', text: 'information', color: '#7499FF'},
            {key: 2, name: 'warning', text: 'warning',  color: 'yellow'},
            {key: 3, name: 'average', text: 'average',  color: 'darkorange'},
            {key: 4, name: 'high', text: 'high',  color: 'orangered'},
            {key: 5, name: 'disaster', text: 'disaster',  color: 'firebrick'},
          ],         
      }
  },
  methods: {
    triggerPriorityMixin_priorityClass (priority) {
      return this.triggerPriorityMixin_priorityMapping.find(p => p.key === Number(priority)).color;
    },
    triggerPriorityMixin_priorityText: function (priority) {
      return this.triggerPriorityMixin_priorityMapping.find(p => p.key === Number(priority)).text;
    }
  }
}